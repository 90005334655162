import { gql } from 'graphql-request';
import { MATRIX, VARIANCE, FULL_PICTURE, QUERY_BUILDER, SALES_VS_GAPS } from '@/consts/reportTypes';
import { getDateForReportApi } from '@/functions/datetime';
import { gqlRequest } from '@/functions/gqlRequest';

interface ExportDetails {
  file_type: string;
  reporter: string;
  customer: string;
  customer_address: string;
  currency_symbol: string;
  customer_mode?: string;
}

interface ExportParameters {
  queryType: string;
  requestParameters?: string;
  exportDetails: ExportDetails;
  additionalFields?: string;
  resultFields?: string;
  delimiter?: string;
  debug?: boolean;
}

async function exportData({
  queryType,
  requestParameters,
  exportDetails,
  additionalFields = '',
  resultFields = 'status',
  delimiter = 'delimiter: COMMA',
  debug = true
}: ExportParameters) {
  try {
    const query = gql`{ ${queryType}(
      ${requestParameters || ''}
      export_details: {
        file_type: ${exportDetails.file_type},
        reporter: "${exportDetails.reporter}",
        customer: "${exportDetails.customer}",
        customer_address: "${exportDetails.customer_address}",
        currency_symbol: "${exportDetails.currency_symbol}",
        customer_mode: ${!!exportDetails.customer_mode},
        ${delimiter}
      },
      ${additionalFields}
    ) { 
        ${resultFields}
      }
    }`;
    const response = await gqlRequest(query, {}, { debug });
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function exportMatrix(
  requestParameters: string, 
  exportDetails: ExportDetails,
  debug: boolean = true
) {
  const params: ExportParameters = {
    queryType: 'scheduleexportmatrix',
    requestParameters,
    exportDetails,
    resultFields: 'status',
    delimiter: 'delimiter: COMMA',
    debug
  };
  return exportData(params);
}

export async function exportVariance(
  requestParameters: string, 
  exportDetails: ExportDetails,
  cols: string,
  debug: boolean = true
) {
  const params: ExportParameters = {
    queryType: 'scheduleexportvariance',
    requestParameters,
    exportDetails,
    additionalFields: `cols: [${cols}]`,
    resultFields: 'status',
    delimiter: 'delimiter: COMMA',
    debug
  };
  return exportData(params);
}

export async function exportFullPicture(
  requestParameters: string, 
  exportDetails: ExportDetails,
  debug: boolean = true
) {
  const params: ExportParameters = {
    queryType: 'scheduleexportfullpicture',
    requestParameters,
    exportDetails,
    resultFields: 'status',
    delimiter: 'delimiter: COMMA',
    debug
  };
  return exportData(params);
}

export async function exportQueryBuilder(
  requestParameters: string, 
  exportDetails: ExportDetails,
  debug: boolean = true
) {
  const params: ExportParameters = {
    queryType: 'scheduleexportquerybuilder',
    requestParameters,
    exportDetails,
    resultFields: 'status',
    debug
  };
  return exportData(params);
}

export async function exportSalesVsGaps(
  requestParameters: string, 
  exportDetails: ExportDetails,
  debug: boolean = true
) {
  const params: ExportParameters = {
    queryType: 'scheduleexportsvg',
    requestParameters,
    exportDetails,
    resultFields: 'status',
    delimiter: 'delimiter: COMMA',
    debug
  };
  return exportData(params);
}

export async function exportSnapshot(
  exportDetails: ExportDetails,
  date_from: string,
  date_to: string,
  date_from2: string,
  date_to2: string,
  account_number: string,
  debug: boolean = true
) {
  const escapedAddress = exportDetails.customer_address.replace(/"/g, '\\"');
  const additionalFields = `date_range_1: { 
    from: "${getDateForReportApi(date_from)}",
    to: "${getDateForReportApi(date_to)}",
    complete_periods_only: false
  },
  date_range_2: {
    from: "${getDateForReportApi(date_from2)}",
    to: "${getDateForReportApi(date_to2)}",
    complete_periods_only: false
  },
  account_numbers: ["${account_number}"]`;

  const params: ExportParameters = {
    queryType: 'scheduleexportsnapshot',
    exportDetails: {
      ...exportDetails,
      customer_address: escapedAddress
    },
    additionalFields,
    resultFields: 'status',
    debug
  };
  return exportData(params);
}

export async function exportReport(
  reportType: string,
  requestParameters: string,
  exportDetails: ExportDetails,
  cols?: string,
  date_from?: string,
  date_to?: string,
  date_from2?: string,
  date_to2?: string,
  account_number?: string,
  debug: boolean = true
) {
  switch (reportType) {
  case MATRIX:
    return exportMatrix(requestParameters, exportDetails, debug);
  case VARIANCE:
    return exportVariance(requestParameters, exportDetails, cols, debug);
  case FULL_PICTURE:
    return exportFullPicture(requestParameters, exportDetails, debug);
  case QUERY_BUILDER:
    return exportQueryBuilder(requestParameters, exportDetails, debug);
  case SALES_VS_GAPS:
    return exportSalesVsGaps(requestParameters, exportDetails, debug);
  case 'snapshot':
    return exportSnapshot(exportDetails, date_from, date_to, date_from2, date_to2, account_number, debug);
  default:
    throw new Error(`Unknown report type: ${reportType}`);
  }
}