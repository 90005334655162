import { httpRequest } from '@/sales-i-utils';

/**
 * 
 * @param path for example notification.path
 * @returns url for file
 */
export async function getFileUrl(path: string): Promise<string | null> {
  try {
    const url = `${process.env.BASE_URL}${path}`;
    const response = await httpRequest('get', url);
    const fileUrl = response?.file_url;
    if (fileUrl) {
      return fileUrl;
    } else {
      throw new Error('File URL not found in response');
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}