import { BehaviorSubject } from 'rxjs';

export const mailComposeState = new BehaviorSubject({
  composing: {},
  currentComposeId: null,
  isExpanded: false,
  hasSentMessage: false
});

export function updateCompose(messageDetails, expand = true, hasSentMessage = false) {
  mailComposeState.next({
    composing: {
      ...mailComposeState.value.composing,
      [messageDetails.id]: messageDetails
    },
    currentComposeId: messageDetails.id,
    isExpanded: expand,
    hasSentMessage
  });
}

